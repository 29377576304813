<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item style="cursor: pointer" @click="$router.push({ name: 'root' })">
                    Tableau de bord
                </vk-breadcrumb-item>
                <vk-breadcrumb-item :disabled="true">Paramètre du tableau de bord</vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <div class="mb-3">Gestion d'affichage du tableau de bord</div>
            <div v-if="dashboardConfig.fetchingDataInProgress">
                <div class="uk-margin-left uk-inline" uk-spinner="ratio:0.75"></div>
                Récupération des données en cours...
            </div>
            <div v-else>
                <div class="m-3">
                    <input
                        type="checkbox"
                        class="uk-checkbox m-lg-2"
                        id="ticketNumber"
                        v-model="dashboardConfig.data.ticketNumber"
                    />
                    <label for="ticketNumber" class="form-label">Nombre de ticket ouverts / fermés</label>
                </div>
                <div class="m-3">
                    <input
                        type="checkbox"
                        class="uk-checkbox m-lg-2"
                        id="quotationNumber"
                        v-model="dashboardConfig.data.quotationNumber"
                    />
                    <label for="quotationNumber" class="form-label"
                        >Nombre de devis en attentes / validés / refusés</label
                    >
                </div>
                <div class="m-3">
                    <input
                        type="checkbox"
                        class="uk-checkbox m-lg-2"
                        id="ticketNumberPerCity"
                        v-model="dashboardConfig.data.ticketNumberPerCity"
                    />
                    <label for="ticketNumberPerCity" class="form-label">Nombre de tickets par ville</label>
                </div>
                <div class="m-3">
                    <input
                        type="checkbox"
                        class="uk-checkbox m-lg-2"
                        id="averageResponseTime"
                        v-model="dashboardConfig.data.averageResponseTime"
                    />
                    <label for="averageResponseTime" class="form-label"
                        >Temps moyen de réponses par ticket / prestataire / agence</label
                    >
                </div>
                <div class="m-3">
                    <input
                        type="checkbox"
                        class="uk-checkbox m-lg-2"
                        id="ticketMap"
                        v-model="dashboardConfig.data.ticketMap"
                    />
                    <label for="ticketMap" class="form-label">Affichage sur Map des Tickets</label>
                </div>
                <div class="m-3">
                    <input
                        type="checkbox"
                        class="uk-checkbox m-lg-2"
                        id="missionOrderMap"
                        v-model="dashboardConfig.data.missionOrderMap"
                    />
                    <label for="missionOrderMap" class="form-label">Affichage sur Map des Ordres de mission</label>
                </div>
                <div class="m-3">
                    <vk-button
                        :disabled="dashboardConfig.updateInProgress"
                        style="border-radius: 5px"
                        type="primary"
                        @click="updateDashboardConfigData(dashboardConfig.data)"
                    >
                        Enregistrer
                        <div
                            v-if="dashboardConfig.updateInProgress"
                            class="uk-margin-left uk-inline"
                            uk-spinner="ratio:0.75"
                        ></div>
                    </vk-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'DashboardConfig',
    data: () => ({}),
    computed: {
        ...mapGetters('dashboard', ['dashboardConfig']),
    },
    methods: {
        ...mapActions('dashboard', ['getDashboardConfigData', 'updateDashboardConfigData']),
    },
    async mounted() {
        await this.getDashboardConfigData()
    },
}
</script>
<style scoped></style>
